import React from 'react'
import { Button } from './Button'

const ButtonGreen = (props) => {
  return(
    <Button
      {...props}
      background='#008E8C'
    />
  )
}

export { ButtonGreen }