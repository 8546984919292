import React, {useLayoutEffect, useRef} from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";


const ByGroupGraph = ({reportRows}) => {

  const chart = useRef(null)

  useLayoutEffect(() => {
    let x = am4core.create("by-group-graph", am4charts.XYChart)

    x.data = reportRows.filter(row => row['Month/Year'] !== 'Totali')
      .map(row => ({
        name: row['Month/Year'],
        totalOpened: row['Totale Aperti'],
        totalClosed: row['Totale Chiusi']
      }))

    let categoryAxis = x.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.title.text = "Month/Year"
    categoryAxis.dataFields.category = "name"
    categoryAxis.title.fontWeight = "bold"
    categoryAxis.renderer.grid.template.location = 0

    let valueAxis = x.yAxes.push(new am4charts.ValueAxis())
    valueAxis.tooltip.disabled = true
    valueAxis.title.text = "Totale"
    valueAxis.title.fontWeight = "bold"
    valueAxis.min = 0
    valueAxis.renderer.minWidth = 35

    let seriesClosed = x.series.push(new am4charts.ColumnSeries())
    seriesClosed.dataFields.categoryX = "name"
    seriesClosed.dataFields.valueY = "totalClosed"
    seriesClosed.tooltipText = "{valueY.value}"
    seriesClosed.columns.template.fill = am4core.color("#075351")
    x.cursor = new am4charts.XYCursor()

    let seriesOpened = x.series.push(new am4charts.ColumnSeries())
    seriesOpened.dataFields.categoryX = "name"
    seriesOpened.dataFields.valueY = "totalOpened"
    seriesOpened.tooltipText = "{valueY.value}"
    seriesOpened.columns.template.fill = am4core.color("#008E8C")
    x.cursor = new am4charts.XYCursor()

    x.legend = new am4charts.Legend()
    //x.legend.position = 'top'
    seriesClosed.legendSettings.labelText = "Closed"
    seriesOpened.legendSettings.labelText = "Opened"

    chart.current = x

    return () => {
      x.dispose()
    }
  }, [reportRows])

  return (
    <div id="by-group-graph" style={{width: "100%", height: "500px"}}/>
  )

}

export default ByGroupGraph