import React  from 'react'
import styled from 'styled-components'
import History from './History'

class PrintTicket extends React.Component {

  render() {
    const { ticket, customer } = this.props

    return (
      <ToPrint>
        <Header>
          Ticket {ticket.id}
        </Header>
        <TicketInfo>
          <TicketInfoColumn>
            <TicketInfoField>
              <TicketInfoFieldName>Cliente</TicketInfoFieldName>
              <TicketInfoFieldValue>{customer.name || customer.structureName} {customer.surname || customer.businessName}</TicketInfoFieldValue>
            </TicketInfoField>
            <TicketInfoField>
              <TicketInfoFieldName>Tipo</TicketInfoFieldName>
              <TicketInfoFieldValue>{ticket.type && ticket.type.name}</TicketInfoFieldValue>
            </TicketInfoField>
            <TicketInfoField>
              <TicketInfoFieldName>Sorgente</TicketInfoFieldName>
              <TicketInfoFieldValue>{ticket.source && ticket.source.name}</TicketInfoFieldValue>
            </TicketInfoField>
            <TicketInfoField>
              <TicketInfoFieldName>Priorità</TicketInfoFieldName>
              <TicketInfoFieldValue>{ticket.priority && ticket.priority.name}</TicketInfoFieldValue>
            </TicketInfoField>
            <TicketInfoField>
              <TicketInfoFieldName>Categoria</TicketInfoFieldName>
              <TicketInfoFieldValue>{ticket.category && ticket.category.name}</TicketInfoFieldValue>
            </TicketInfoField>
          </TicketInfoColumn>
          <TicketInfoColumn>
            <TicketInfoField>
              <TicketInfoFieldName>Sottocategoria</TicketInfoFieldName>
              <TicketInfoFieldValue>{ticket.subcategory && ticket.subcategory.name}</TicketInfoFieldValue>
            </TicketInfoField>
            <TicketInfoField>
              <TicketInfoFieldName>Tipo di problema</TicketInfoFieldName>
              <TicketInfoFieldValue>{ticket.subcategory && ticket.subcategory.name}</TicketInfoFieldValue>
            </TicketInfoField>
            <TicketInfoField>
              <TicketInfoFieldName>Telefono</TicketInfoFieldName>
              <TicketInfoFieldValue>{ticket.phone}</TicketInfoFieldValue>
            </TicketInfoField>
            <TicketInfoField>
              <TicketInfoFieldName>Email</TicketInfoFieldName>
              <TicketInfoFieldValue>{ticket.email}</TicketInfoFieldValue>
            </TicketInfoField>
          </TicketInfoColumn>
        </TicketInfo>
        { ticket.stockists && ticket.stockists.length > 0 &&
          <Stockists>
            <StockistsTitle>Stockists</StockistsTitle>
            {ticket.stockists && ticket.stockists.map(s =>
              <StockistsItem key={s.id}>{s.name || s.businessName} {s.surname || s.structureName}</StockistsItem>
            )}
          </Stockists>
        }
        <History history={ticket.history} />
      </ToPrint>
    )
  }
}

export default PrintTicket

//region Style

const ToPrint = styled.div`
  overflow: hidden; 
  height: 0;
  width: 0;
  color: #000000;
  
  @media print {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 177.8mm;
    height: unset;
    @page {
      size: 177.8mm 234.95mm;
      margin: 3mm 16mm 7mm 16mm
    }
  }
`

const TicketInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`

const TicketInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 70mm;
  
  &:first-child {
    margin-right: 20px;
  }
`

const TicketInfoField = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 70mm;
  padding: 3px;
  margin-bottom: 5px;
  font-size: 1rem;
`

const TicketInfoFieldName = styled.div`
  font-weight: bold;
`

const TicketInfoFieldValue = styled.div`
`

const Header = styled.div`
  width: calc(177.8mm - 32mm);
  margin-bottom: 25px;
  text-align: center;
  font-size: 2.3rem;
`

const Stockists = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 380px;
  border-bottom: 1px solid #000000;
  max-height: 400px;
  overflow-y: auto;
`

const StockistsTitle = styled.div`
  font-size: 1.05rem;
  font-weight: bolder;
  margin: 20px 0;
  text-align: left;
  width: 380px;
`

const StockistsItem = styled.div`
  box-sizing: border-box;
  opacity: 0.8;
  width: 380px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
  font-size: 0.8rem;
  padding: 7px 4px;
  
  :nth-child(even){
    background:#ECECEC;
  }
  :nth-child(odd){
      background:#fafafa;
  }
`

//endregion