import React, {useState, useEffect, useRef, useLayoutEffect} from 'react'
import styled from 'styled-components'
import useEntities from '../../../hooks/useEntities'
import moment from 'moment'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'


const CreatedLast7Dats = () => {

  const chartRef = useRef(null)
  const [stats, setStats] = useState([])

  const { call: getStats } = useEntities('', { withoutNotifications: true, withoutEffect: true })

  useEffect(() => {
    getStats('get', '/home/new-tickets-weekly')
      .then(({ payload }) => setStats(payload))
      .catch(e => console.log(e))
  }, [])

  useLayoutEffect(() => {
    const chart = am4core.create("created-last-14-days", am4charts.XYChart)
    chart.paddingRight = 20

    chart.data = [...new Array(14).keys()].reverse()
      .map(i => moment().subtract(i, 'days').format('MM-DD'))
      .map(d => ({ category: d, value: stats[d] || 0}))

    // Create axes
    const xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    xAxis.dataFields.category = "category"

    const yAxis = chart.yAxes.push(new am4charts.ValueAxis())

    const series = chart.series.push(new am4charts.LineSeries())
    series.dataFields.valueY = "value"
    series.dataFields.categoryX = "category"
    series.strokeWidth = 3
    series.stroke = am4core.color("#075351")
    series.tensionX = 0.8
    const bullet = new am4charts.CircleBullet()
    bullet.fill = am4core.color("#008E8C")
    bullet.horizontalCenter = "middle"
    bullet.verticalCenter = "middle"
    series.bullets.push(bullet)
    const labelBullet = series.bullets.push(new am4charts.LabelBullet())
    labelBullet.label.text = "{value}"
    labelBullet.label.dy = -20
    series.connect = false

    chartRef.current = chart

    return () => {
      chart.dispose()
    }
  }, [stats])


  return (
    <Container>
      <Title>Ticket creati negli ultimi 14 giorni</Title>
      <div id="created-last-14-days" style={{ width: '100%', height: '300px' }}/>
    </Container>
  )
}

export default CreatedLast7Dats

//region Style

const Container = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
`

const Title = styled.div`
  width: 100%;
  font-size: 1.2rem;
  opacity: 0.85;
  margin-bottom: 10px;
  text-align: center;
`

//endregion