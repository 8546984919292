import React from 'react'
import {Field, Form} from 'react-final-form'
import {Input} from '../../common'
import styled from 'styled-components'

const LoginForm = ({ onSubmit, loginError }) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <InputContainer>
            <Field
              data-cy="input-OTP"
              name="otp"
              component={Input}
              type="password"
              label="Codice OTP"
              autoFocus
            />
          </InputContainer>
          {loginError
            ?
            (
              <ButtonError data-cy="OTP-error">
                Login Errato
              </ButtonError>
            )
            :
            (
              <ButtonLogin type='submit' onClick={handleSubmit} data-cy="OTP-confirm">
                Accedi
              </ButtonLogin>
            )
          }
        </form>
      )}
    />
  )
}

export default LoginForm

//regione Style

const InputContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 35px;
  margin-top: 35px;
  color: #FFFFFF;
`

const Button = styled.button`
  border: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-weight: bold;
  opacity: 0.9;
  font-size: 1.2rem;
  height: 70px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  cursor: pointer;
`

const ButtonLogin = styled(Button)`
  background: #075351;
`

const ButtonError = styled(Button)`
  background: #941212;
`


//endregion