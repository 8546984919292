import React from 'react'
import {Field, Form} from 'react-final-form'
import {Input} from '../../common'
import styled from 'styled-components'

const LoginForm = ({onSubmit, loginError, setForgot}) => {
  return (
    <Form
      onSubmit={onSubmit}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <InputContainer>
            <Field
              data-cy="input-username"
              name="username"
              component={Input}
              type="text"
              label="Utente"
              autoFocus
            />
          </InputContainer>
          <InputContainer>
            <Field
              data-cy="input-password"
              name="password"
              component={Input}
              type="password"
              label="Password"
            />
          </InputContainer>
          <Forgot data-cy={'reset-password'} onClick={() => setForgot(true)}>Password dimenticata?</Forgot>
          <Disclaimer>
            This is a Restricted System and must only be accessed by authorized users.
            Unauthorized users attempting to gain access are subject to prosecution.
          </Disclaimer>
          {loginError
            ?
            (
              <ButtonError data-cy="login-error">
                Login Errato
              </ButtonError>
            )
            :
            (
              <ButtonLogin type='submit' onClick={handleSubmit} data-cy="button-login">
                Accedi
              </ButtonLogin>
            )
          }
        </form>
      )}
    />
  )
}

export default LoginForm

//region Style

const InputContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 35px;
  margin-top: 35px;
  color: #FFFFFF;
`

const Button = styled.button`
  border: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-weight: bold;
  opacity: 0.9;
  font-size: 1.2rem;
  height: 70px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  cursor: pointer;
`

const ButtonLogin = styled(Button)`
  background: #075351;
`

const ButtonError = styled(Button)`
  background: #941212;
`

const Forgot = styled.div`
  color: #FFFFFF;
  text-align: center;
  margin-top: 20px;
  font-size: 0.85rem;
  cursor: pointer;
  
  &:hover {
    color: #ececec;
  }
`
const Disclaimer = styled.div`
  color: #FFFFFF;
  margin-left: 8px;
  margin-right: 8px;
  text-align: center;
  margin-top: 20px;
  font-size: 0.8rem;
  opacity: 0.7;
`

//endregion