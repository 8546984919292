import React, {useLayoutEffect, useRef} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'


const ByAgentGraph = ({reportRows, dateArray}) => {

  const chart = useRef(null);

  useLayoutEffect(() => {
    let x = am4core.create("by-agent-graph", am4charts.XYChart)

    x.data = reportRows.filter(row => row['Agent'] !== 'Totali').map(row => ({
      name: row.Agent,
      totalOpened: row['Totale Aperti'],
      totalClosed: row['Totale Chiusi']
    }))

    let categoryAxis = x.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.title.text = "Nome Agente"
    categoryAxis.title.fontWeight = "bold"
    categoryAxis.dataFields.category = "name"
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.labels.template.rotation = 270

    let valueAxis = x.yAxes.push(new am4charts.ValueAxis())
    valueAxis.tooltip.disabled = true
    valueAxis.title.text = "Totale"
    valueAxis.title.fontWeight = "bold"
    valueAxis.min = 0
    valueAxis.renderer.minWidth = 35

    let seriesClosed = x.series.push(new am4charts.ColumnSeries())
    seriesClosed.dataFields.categoryX = "name"
    seriesClosed.dataFields.valueY = "totalClosed"
    seriesClosed.tooltipText = "{valueY.value}"
    seriesClosed.columns.template.fill = am4core.color("#075351")
    x.cursor = new am4charts.XYCursor()

    let seriesOpened = x.series.push(new am4charts.ColumnSeries())
    seriesOpened.dataFields.categoryX = "name"
    seriesOpened.dataFields.valueY = "totalOpened"
    seriesOpened.tooltipText = "{valueY.value}"
    seriesOpened.columns.template.fill = am4core.color("#008E8C")
    x.cursor = new am4charts.XYCursor()

    x.legend = new am4charts.Legend()
    //x.legend.position = 'top'
    seriesClosed.legendSettings.labelText = "Closed"
    seriesOpened.legendSettings.labelText = "Opened"

    chart.current = x

    return () => {
      x.dispose()
    }
  }, [reportRows])

  return (
    <div id="by-agent-graph" style={{width: "100%", height: "650px"}}/>
  )

}

export default ByAgentGraph