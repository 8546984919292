import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import {login, sendOtp, resetFilters} from '../../../actions'
import {getError, getOtpNeeded} from '../../../reducers'
import imgLogo from '../../../images/logo.png'
import LoginForm from './LoginForm'
import OTPForm from './OTPForm'
import ForgotPassword from './ForgotPassword'


const Login = ({history}) => {

  const loginError = useSelector(getError)
  const otpNeeded = useSelector(getOtpNeeded)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetFilters())
  }, [dispatch])

  const [forgot, setForgot] = useState(false)
  const onSubmitLogin = values => {
    dispatch(login(values, history))
  }

  const onSubmitOTP = values => {
    dispatch(sendOtp(values, otpNeeded, history))
  }

  return (
    <>
      <LoginContainer>
        <Logo src={imgLogo} alt='logo'/>
        {otpNeeded &&
        <OTPForm
          loginError={loginError}
          onSubmit={onSubmitOTP}
        />
        }
        {forgot &&
        <ForgotPassword setForgot={setForgot}/>
        }
        {!otpNeeded && !forgot &&
        <LoginForm
          loginError={loginError}
          onSubmit={onSubmitLogin}
          setForgot={setForgot}
        />
        }
      </LoginContainer>
    </>
  )
}

/* region STYLE */

const LoginContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 550px;
  width: 350px;
  background: #008E8C;
  border-radius: 15px;
  box-shadow: 0 0 16px 1px rgba(0,0,0,0.75);
  border: 1px solid #818181;
`

const Logo = styled.img`
  padding: 30px;
  box-sizing: border-box;
  width: 100%;
  height: 130px;
  margin-top: 15px;
`

/* endregion */

export default Login