import React from 'react'
import {Link} from 'react-router-dom'
import {Field, Form} from 'react-final-form'
import styled from 'styled-components'
import {ButtonBlu, ButtonGreen, ButtonRed, DatePicker, Input, Select} from '../../common'
import imgSearch from '../../../images/ico-search.svg'
import imgAdd from '../../../images/ico-add.svg'
import imgReset from '../../../images/resetFilters.svg'
import imgArrowUp from '../../../images/up-arrow.svg'
import imgArrowDown from '../../../images/down-arrow.svg'
import imgExcel from '../../../images/excel.svg'
import {createExcel} from '../../../helpers/createExcel'
import moment from 'moment'

const TicketFilters = (props) => {

  const {
    onSubmit, states,
    priorities, categories,
    subcategories, problemTypes,
    ticketType, idCustomer,
    user, open, entities,
    match, users, filters,
    setOpen, setUsedFilters,
    worklist, initialLimit, total
  } = props


  //region Advanced Filters
  const resetAdvancedFilters = (args, state, {changeValue}) => {
    state.fields['subcategory'].change(null)
    state.fields['problemType'].change(null)
  }

  const filterSubcategories = (subcategories, categoryId) => {
    let filtered = subcategories
    if (categoryId)
      filtered = subcategories && subcategories.filter(sub => sub.category.id === parseInt(categoryId))
    return filtered.map(entity =>
      <option key={entity.id} value={entity.id}>{entity.name}</option>
    )
  }

  const filterProblemTypes = (categoryId, categories) => {
    if (!categoryId) return []
    if (!categories || categories.length === 0) return []

    let filtered = categories.find(c => c.id === parseInt(categoryId)).problemTypes
    return filtered.map(entity =>
      <option key={entity.id} value={entity.id}>{entity.name}</option>
    )
  }
  //endregion

  const getInitialValues = (filters) => {
    if (filters.fromDate)
      filters.fromDate = new Date(filters.fromDate)
    if (filters.toDate)
      filters.toDate = new Date(filters.toDate)
    return filters
  }

  const onSearch = (values) => {
    if (idCustomer)
      values.customerId = idCustomer
    if (user) {
      values.user = user
      values.opened = true
    }
    const filters = {...values}
    filters.fromDate = values.fromDate
    filters.toDate = values.toDate
    const usedFilter = Object.keys(filters).filter(k => {
      if (
        k === 'customerId' || k === 'user' ||
        k === 'opened' || k === "orderBy" ||
        k === "orderType") return false
      return !!values[k]
    })
    filters.orderBy = 'created'
    filters.orderType = 'DESC'
    filters.limit = initialLimit
    setUsedFilters(usedFilter.length > 0)
    onSubmit(filters)
    setOpen(false)
  }

  const saveXls = async () => {
    if (total === 0) return
    let header
    let filename
    let data
    if (worklist) {
      header = ['Ticket id', 'Priority', 'Status', 'Created', 'Assigned By', 'Customer', 'Sub Category', 'Problem Type', 'Updated']
      filename = 'MyWorklist'
      data = entities.map(e => ({
        ["Ticket id"]: e.id,
        Priority: e.priority.name,
        Status: e.state.name,
        Created: moment(e.created).format('DD-MM-YYYY hh:mm'),
        ['Assigned By']: `${e.user.surname} ${e.user.name}`,
        Customer: e.customer.name ? `${e.customer.name} ${e.customer.surname}` : `${e.customer.structureName} ${e.customer.businessName}`,
        ['Sub Category']: e.subcategory.name,
        ['Problem Type']: e.problemType.name,
        Updated: moment(e.updated).format('DD-MM-YYYY hh:mm'),
      }))
    } else {
      header = ['Ticket id', 'Priority', 'Status', 'Created','Updated','Customer', 'Category', 'Sub Category', 'Assigned To', 'Problem Type', 'Company' ]
      filename = 'CercaTicket'
      data = entities.map(e => ({
        ["Ticket id"]: e.id,
        Priority: e.priority.name,
        Status: e.state.name,
        Created: moment(e.created).format('DD-MM-YYYY hh:mm'),
        Customer: e.customer.name ? `${e.customer.name} ${e.customer.surname}` : `${e.customer.structureName} ${e.customer.businessName}`,
        Category: e.category.name,
        ['Sub Category']: e.subcategory.name,
        ['Problem Type']: e.problemType.name,
        ['Assigned To']: `${e.user.surname} ${e.user.name}`,
        Company: e.company === 1 ? 'MSD' : 'Organon',
        Updated: moment(e.updated).format('DD-MM-YYYY hh:mm'),
      }))
    }

    const blob = await createExcel(filename, data, header)
    const a = document.createElement('a')
    a.style.display = 'none'
    document.body.appendChild(a)

    const url = window.URL.createObjectURL(blob)

    a.href = url
    a.download = filename + '.xlsx'

    a.click()

    window.URL.revokeObjectURL(url)

    if (a && a.parentElement) {
      a.parentElement.removeChild(a)
    }
  }

  //region ShowFilters
  const openFilters = (e) => {
    e.preventDefault()
    setOpen(!open)
  }
  const resetFilters = (args, state, {changeValue}) => (values) => {
    Object.keys(values).filter(k => {
      if (
        k === 'customerId' || k === 'user' ||
        k === 'opened' || k === "orderBy" ||
        k === "orderType") return false
      return !!values[k]
    }).forEach(k => {
      state.fields[`${k}`].change(null)
    })
  }
  const disableResetFilters = (submitting, values) => {
    const usedFilter = Object.keys(values).filter(k => {
      if (
        k === 'customerId' || k === 'user' ||
        k === 'opened' || k === "orderBy" ||
        k === "orderType" || k === 'limit'
      ) return false
      return !!values[k]
    })
    return !(submitting || usedFilter.length > 0)
  }
  const ShowFilters = (values) => {
    const usedFilters = Object.keys(values)
      .filter(k => {
        if (
          k === 'fromDate' || k === 'toDate' ||
          k === 'customerId' || k === 'user' ||
          k === 'opened' || k === "orderBy" ||
          k === "orderType" || k === 'id' ||
          k === 'limit') return false
        return !!values[k]
      })
    return (
      <ShowFiltersButton
        data-cy={'show-filters'}
        background='#008E8C'
        onClick={openFilters}
      >
        <ButtonImage src={open ? imgArrowUp : imgArrowDown}/>
        {
          usedFilters.length > 0 &&
          <ValorizedFilter data-cy={'filters-notification'}/>
        }
      </ShowFiltersButton>
    )
  }
  //endregion

  return (
    <FormContainer>
      <Form
        mutators={{
          resetAdvancedFilters,
          resetFilters
        }}
        onSubmit={onSearch}
        initialValues={getInitialValues(filters)}
        render={({handleSubmit, values, form, submitting}) => (
          <form autoComplete="off" onSubmit={handleSubmit}>
            <FilterBar>
              <DateBox data-cy={'filters-from'}>
                <Field
                  name="fromDate"
                  component={DatePicker}
                  type="text"
                  label="Dal"
                  maxDate={new Date()}
                />
              </DateBox>
              <DateBox data-cy={'filters-to'}>
                <Field
                  name="toDate"
                  component={DatePicker}
                  type="text"
                  label="Al"
                  maxDate={new Date()}
                />
              </DateBox>
              <InputBox data-cy={'filters-id'}>
                <Field
                  name="id"
                >
                  {({input}) => (
                    <Input
                      type="text"
                      label="Id ticket"
                      minWidth={200}
                      {...input}
                      onChange={(args) => {
                        const notANumber = args.target.value.match(/[A-z]/g)
                        if (!notANumber)
                          input.onChange(args)
                      }}
                    />)}
                </Field>
              </InputBox>
              <ButtonBlu dataCy='filters-search' text='Cerca' image={imgSearch}/>
              <ButtonRed
                image={imgReset}
                dataCy={'filters-reset'}
                text={'Resetta'}
                type="button"
                onClick={(e) => form.mutators.resetFilters()(values)}
                disabled={disableResetFilters(submitting, values)}
              />
              {idCustomer &&
              <LinkStyled data-cy={'ticket-new'} to={`/customers/${idCustomer}/tickets/new`}>
                <ButtonGreen text='Nuovo' image={imgAdd}/>
              </LinkStyled>
              }
              <ButtonGreen
                dataCy='filters-excel' text='Esporta' image={imgExcel}
                disabled={total === 0}
                onClick={() => saveXls()}
              />
              {
                ShowFilters(values)
              }
              {worklist && <TicketCount>Ticket nella Worklist: <b>{total}</b></TicketCount>}
              {!worklist && (total ? <TicketCount>Numero Ticket: <b>{total}</b></TicketCount> : '')}
              <FilterContainer open={open}>
                {match.path !== '/my-tickets' && <FilterRow>
                  <InputBox data-cy={'filters-assignTo'}>
                    <Field
                      name="assignedTo"
                      component={(props) => <Select {...props} loading={users.length === 0}/>}
                      type="text"
                      label="Assegnato a"
                    >
                      <option value={null}/>
                      {users && users.map(entity =>
                        <option style={{color: entity.enabled ? '#000000' : '#999999'}} key={entity.id}
                                value={entity.id}>{entity.surname} {entity.name}</option>
                      )}
                    </Field>
                  </InputBox>
                  <InputBox data-cy={'filters-createdBy'}>
                    <Field
                      name="createdBy"
                      component={(props) => <Select {...props} loading={users.length === 0}/>}
                      type="text"
                      label="Creato da"
                    >
                      <option value={null}/>
                      {users && users.map(entity =>
                        <option style={{color: entity.enabled ? '#000000' : '#999999'}} key={entity.id}
                                value={entity.id}>{entity.surname} {entity.name}</option>
                      )}
                    </Field>
                  </InputBox>
                </FilterRow>}
                <FilterRow>
                  {!idCustomer &&
                  <InputBox data-cy={'filters-customer'}>
                    <Field
                      name="customer"
                      component={Input}
                      type="text"
                      label="Cliente"
                    />
                  </InputBox>
                  }
                  <InputBox data-cy={'filters-company'}>
                    <Field
                      name="company"
                      component={(props) => <Select {...props} loading={states.length === 0}/>}
                      type="text"
                      label="Azienda"
                    >
                      <option value={null}/>
                      <option value={1}>Msd</option>
                      <option value={2}>Organon</option>
                    </Field>
                  </InputBox>
                </FilterRow>
                <FilterRow>
                  <InputBox data-cy={'filters-state'}>
                    <Field
                      name="states"
                      component={(props) => <Select {...props} loading={states.length === 0}/>}
                      type="text"
                      label="Stato Ticket"
                    >
                      <option value={null}/>
                      {states && states
                        .filter((entity) => !(user && entity.id === 2))
                        .map(entity =>
                          <option key={entity.id} value={entity.id}>{entity.name}</option>
                        )}
                    </Field>
                  </InputBox>
                  <InputBox data-cy={'filters-type'}>
                    <Field
                      name="type"
                      component={(props) => <Select {...props} loading={ticketType.length === 0}/>}
                      type="text"
                      label="Tipo"
                    >
                      <option value={null}/>
                      {ticketType && ticketType.map(entity =>
                        <option key={entity.id} value={entity.id}>{entity.name}</option>
                      )}
                    </Field>
                  </InputBox>
                  <InputBox data-cy={'filters-priority'}>
                    <Field
                      name="priority"
                      component={(props) => <Select {...props} loading={priorities.length === 0}/>}
                      type="text"
                      label="Priorità"
                    >
                      <option value={null}/>
                      {priorities && priorities.map(entity =>
                        <option key={entity.id} value={entity.id}>{entity.name}</option>
                      )}
                    </Field>
                  </InputBox>
                </FilterRow>
                <FilterRow>
                  <InputBox data-cy={'filters-category'}>
                    <Field
                      name="category"
                    >
                      {({input, meta}) => (
                        <>
                          <Select
                            type="text"
                            label="Categoria"
                            onClick={() => form.mutators.resetAdvancedFilters()}
                            loading={categories.length === 0}
                            {...input}
                          >
                            <option>{null}</option>
                            {categories && categories.map(entity =>
                              <option key={entity.id} value={entity.id}>{entity.name}</option>
                            )}
                          </Select>
                        </>
                      )}
                    </Field>
                  </InputBox>
                  <InputBox maxWidth={350} data-cy={'filters-subcategory'}>
                    <Field
                      name="subcategory"
                    >{({input, meta}) => (
                      <>
                        <Select
                          type="text"
                          label="Sottocategoria"
                          loading={subcategories.length === 0}
                          {...input}
                        >
                          <option>{null}</option>
                          {filterSubcategories(subcategories, values.category)}
                        </Select>
                      </>
                    )}
                    </Field>
                  </InputBox>
                  <InputBox maxWidth={350} data-cy={'filters-problemType'}>
                    <Field
                      name="problemType"
                    >
                      {({input, meta}) => (
                        <>
                          <Select
                            type="text"
                            label="Tipo di problema"
                            loading={problemTypes.length === 0}
                            {...input}
                          >
                            <option>{null}</option>
                            {(values.category && categories) ?
                              filterProblemTypes(values.category, categories) :
                              problemTypes.map((entity) =>
                                <option key={entity.id} value={entity.id}>{entity.name}</option>
                              )
                            }
                          </Select>
                        </>
                      )}
                    </Field>
                  </InputBox>
                  <InputBox minWidth={200} data-cy={'filters-openFromDays'}>
                    <Field
                      name="openFromDays"
                      component={Select}
                      type="text"
                      label="Aperti da"
                    >
                      <option value={null}/>
                      <option value={3}>3 giorni</option>
                      <option value={7}>7 giorni</option>
                      <option value={15}>15 giorni</option>
                    </Field>
                  </InputBox>
                </FilterRow>
              </FilterContainer>
            </FilterBar>
          </form>
        )}
      />
    </FormContainer>
  )
}

/* region STYLE */

const FilterBar = styled.div`
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        position: relative;
        color: #000000 !important;
        margin-bottom: 50px;
        `

const InputBox = styled.div`
        margin: 20px;
        opacity: 0.9;
        min-width: ${({minWidth}) => minWidth ? `${minWidth}px` : '300px'} !important;
        max-width: ${({maxWidth}) => maxWidth ? `${maxWidth}px` : 'unset'} !important;
        `

const DateBox = styled.div`
        margin: 30px;
        min-width: 200px!important;
        `

const FilterContainer = styled.div`
        display: ${props => props.open ? 'flex' : 'none'};
        height: ${props => props.open || '0px !important'};
        flex-direction: column;
        transition: 1s;
        `

const FilterRow = styled.div`
        display: flex;
        flex-direction: row;
        `

const ShowFiltersButton = styled.button`
        position: relative;
        border: 0;
        font-size: 0.9rem;
        min-width: 35px;
        min-height: 34px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;
        background: ${props => props.background ? props.background : '#1A6C9C'};
        color: #FFFFFF;
        padding: 8px 10px;
        margin: 15px;
        cursor: pointer;
        `

const ButtonImage = styled.img`
        width: 18px;
        `

const ValorizedFilter = styled.span`
        position: absolute;
        top: 0;
        right: 0;
        background: red;
        min-width: 7px !important;
        min-height: 7px !important;
        border-radius: 7px;
        `

const LinkStyled = styled(Link)`
        text-decoration: none;
`

const TicketCount = styled.div`
  font-size: 1rem;
  margin-left: 15px;
`

const FormContainer = styled.div`
  display: flex;
`

/* endregion */

export default TicketFilters
