import React from 'react'
import styled from 'styled-components'

const Tr = ({children, ...rest}) => {
  return (
    <TrStyled {...rest}>
      {children}
    </TrStyled>
  )
}

const TrStyled = styled.tr`
  ${({isVisible}) => !isVisible && 'height : 0'};
  height: ${({height}) => height ? `${height}px` : '30px'}
  ${props => props.status === 'parziale' && 'color: #e76708;'}
  ${props => props.status === 'completo' && 'color: #000000;'}
  ${props => props.status === 'aperto' && 'color: #008e8c;'}
  ${props => props.status === 'annullato' && 'color: #a31d1d;'}
  ${props => props.highlighted && 'background: #c7dedd;'}
  border-left: 5px solid transparent;
  
  ${props => props.withoutHover ? '' : '&:hover { background: #BBBBBB; }'}
`

export {Tr}