import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {TableContainer, Table, Tr, Th} from '../../common'
import TicketRow from './TicketRow'
import VisibilitySensor from "react-visibility-sensor";

const TicketRowMemo = React.memo(TicketRow)

const TableTickets = ({opened, closed, order, switchEnabled, userIsAdmin, ...rest}) => {

  const [element, setElement] = useState(null)
  useEffect(() => {
    setElement(document.getElementById("table-container"))
  }, [])

  return (
    <TableContainer id={'table-container'}>
      <Table>
        <thead>
        <tr style={{background: '#e2e2e2'}}>
          <Th/>
          <Th/>
          <Th {...rest} onClick={() => order('id')} field='id' dataCy={`title-id`}>ID</Th>
          <Th {...rest} onClick={() => order('priority')} field='priority' dataCy={`title-priority`}>Priorità</Th>
          <Th {...rest} onClick={() => order('state')} field='state' dataCy={`title-state`}>Stato</Th>
          <Th {...rest} onClick={() => order('created')} field='created' dataCy={`title-created`}>Creazione</Th>
          <Th {...rest} field='updated' dataCy={`title-closed`}>Aggiornamento</Th>
          <Th {...rest} field='assignee' dataCy={`title-assignee`}>Assegnatario</Th>
          <Th {...rest} field='firstnote' dataCy={`title-firstnote`}>Prima nota</Th>
          <Th {...rest} onClick={() => order('category')} field='category' dataCy={`title-category`}>Categoria</Th>
          <Th {...rest} onClick={() => order('subcategory')} field='subcategory'
              dataCy={`title-subcategory`}>Sottocategoria</Th>
        </tr>
        </thead>
        <tbody data-cy={'tickets-table'}>
        {opened && opened.length === 0 &&
        <Tr withoutHover><NoRecords colSpan={12}>Nessun ticket aperto</NoRecords></Tr>
        }
        {opened && opened.map(ticket => (
          <TicketRowMemo key={ticket.id} ticket={ticket} opened={true} isVisible={true}/>
        ))}
        <Separator><td colSpan={12} /></Separator>
        </tbody>
        <thead>
        <tr style={{background: '#e2e2e2'}}>
          <Th/>
          <Th/>
          <Th {...rest} onClick={() => order('id')} field='id' dataCy={`title-id`}>ID</Th>
          <Th {...rest} onClick={() => order('priority')} field='priority' dataCy={`title-priority`}>Priorità</Th>
          <Th {...rest} onClick={() => order('state')} field='state' dataCy={`title-state`}>Stato</Th>
          <Th {...rest} onClick={() => order('created')} field='created' dataCy={`title-created`}>Creazione</Th>
          <Th {...rest} field='closed' dataCy={`title-closed`}>Chiusura</Th>
          <Th {...rest} field='assignee' dataCy={`title-assignee`}>Assegnatario</Th>
          <Th {...rest} field='firstnote' dataCy={`title-firstnote`}>Prima nota</Th>
          <Th {...rest} onClick={() => order('category')} field='category' dataCy={`title-category`}>Categoria</Th>
          <Th {...rest} onClick={() => order('subcategory')} field='subcategory'
              dataCy={`title-subcategory`}>Sottocategoria</Th>
        </tr>
        </thead>
        <tbody data-cy={'tickets-table'}>
        {closed && closed.length === 0 &&
        <Tr withoutHover><NoRecords colSpan={12}>Nessun ticket chiuso</NoRecords></Tr>
        }
        {closed && element && closed.map(ticket => (
          <VisibilitySensor key={ticket.id}>
            {({isVisible, visibilityRect}) =>
              (<TicketRowMemo
                key={ticket.id} ticket={ticket}
                opened={false} isVisible={isVisible}
                element={element} visibilityRect={visibilityRect}
              />)
            }
          </VisibilitySensor>
        ))}
        </tbody>
      </Table>
    </TableContainer>
  )
}

export default TableTickets

//region Style
const NoRecords = styled.td`
  text-align: center;
  font-size: 1.4rem;
  opacity: 0.85;
`

const Separator = styled.tr`
  height: 80px;
`
//endregion
