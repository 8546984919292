import React, {useState, useEffect, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Media from 'react-media'
import {Route, Redirect, useLocation} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import menuItems from '../../constants/menu'
import styled from 'styled-components'
import Topbar from './Topbar'
import Navbar from './Navbar'
import {Loader} from '../common'
import {getToken, getLoading, getUser, getPreviousPath} from '../../reducers'
import { setPreviousPath } from '../../actions'
import imgMenuCollapsed from '../../images/arrow-right.svg'
import imgMenuExpanded from '../../images/arrow-down.svg'


const Template = ({component: Component, ...rest}) => {

  const isFirstRun = useRef(true)
  const dispatch = useDispatch()
  const token = useSelector(getToken) || localStorage.getItem('token')
  const loading = useSelector(getLoading)
  const user = useSelector(getUser)
  const [collapsed, setCollapsed] = useState(false)
  const [prevPath, setPrevPath] = useState(null)
  const { pathname } = useLocation()

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      setPrevPath(pathname)
      return
    }
    dispatch(setPreviousPath(prevPath))
    setPrevPath(pathname)
  }, [pathname])

  return (
    <Route {...rest} >
      {(token || !user.resetPassword)
        ? (
          <>
            {loading && <Loader/>}
            <TemplateContainer>
              <Topbar
                collapsed={collapsed}
              />
              <Navbar
                user={user}
                menuItems={menuItems}
                backgroundPrimaryColor={'#075351'}
                backgroundSecondaryColor={'#008E8C'}
                textColor={'#FFFFFF'}
                hasTopBar={'70px'}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                collapsedImage={imgMenuCollapsed}
                expandedImage={imgMenuExpanded}
              />
              <Media query={{maxWidth: 1024}}>
                {matches =>
                  <Content data-cy={'content'} collapsed={collapsed || matches}>
                    <Component/>
                  </Content>
                }
              </Media>
              <ToastContainer/>
            </TemplateContainer>
          </>
        )
        : (
          <Redirect to='/login'/>
        )
      }
    </Route>
  )
}

/* region STYLE */

const TemplateContainer = styled.div`
  position: absolute;
  box-sizing: border-box;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  background: inherit;
`

const Content = styled.div`
  position: absolute;
  box-sizing: border-box;
  top: 70px;
  bottom: 0;
  left: ${props => props.collapsed ? '64px' : '250px'};
  transition:left 0.3s linear;
  right: 0;
  margin: auto;
  background: inherit;
  overflow: auto;
`

/* endregion */

export default Template
