import React, { useState } from 'react'
import {Field, Form} from 'react-final-form'
import {Input} from '../../common'
import styled from 'styled-components'
import axios from 'axios'
import {baseUrl} from '../../../constants/config'
import {confirmAlert} from 'react-confirm-alert'

const ForgotPassword = ({ setForgot }) => {

  const [ error, setError ] = useState(null)
  const onSubmit = async ({ username }) => {
    try {
      await axios.get(`${baseUrl}/reset-password?username=${encodeURIComponent(username)}`)
      confirmAlert({
        title: 'Controlla la tua email',
        message: 'Al tuo indirizzo di posta associato all\'account deve essere arrivata una email per il reset della password',
        buttons: [
          {label: 'Si', onClick: () => setForgot(false)},
        ]
      })
    } catch (e) {
      setError(true)
      let message
      switch (e.response.status) {
        case 404:
          message = 'Questo utente non esiste'
          break
        default:
          message = 'Qualcosa è andato storto'
      }
      confirmAlert({
        title: 'Errore',
        message,
        buttons: [
          {label: 'Ok', onClick: () => setError(false)}
        ]
      })
    }
  }

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Disclaimer data-cy={'disclaimer-reset-password'}>
            Inserisci il tuo username, ti verrà mandata un'email all'indirizzo registrato nell'account.
          </Disclaimer>
          <InputContainer>
            <Field
              data-cy="input-username"
              name="username"
              component={Input}
              type="text"
              label="Utente"
              autoFocus
            />
          </InputContainer>
          <GoBack data-cy={'go-back'} onClick={() => setForgot(false)}>Torna indietro</GoBack>
          {error
            ?
            (
              <ButtonError data-cy="login-error">
                Errore
              </ButtonError>
            )
            :
            (
              <ButtonLogin type='submit' onClick={handleSubmit} data-cy="button-login">
                Invia
              </ButtonLogin>
            )
          }
        </form>
      )}
    />
  )
}

export default ForgotPassword

//region Style

const InputContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 35px;
  margin-top: 35px;
  color: #FFFFFF;
`

const Button = styled.button`
  border: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
  font-weight: bold;
  opacity: 0.9;
  font-size: 1.2rem;
  height: 70px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  cursor: pointer;
`

const ButtonLogin = styled(Button)`
  background: #075351;
`

const ButtonError = styled(Button)`
  background: #941212;
`

const Disclaimer = styled.div`
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 20px;
  font-size: 0.85rem;
  padding: 5px 15px;
`

const GoBack = styled.div`
  color: #FFFFFF;
  text-align: center;
  margin-top: 150px;
  font-size: 0.85rem;
  cursor: pointer;
  
  &:hover {
    color: #ececec;
  }
`

//endregion