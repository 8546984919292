import React, {useEffect, useRef, useState} from 'react'
import {useHistory, useRouteMatch} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import useEntities from '../../../hooks/useEntities'
import useTitle from '../../../hooks/useTitle'
import FormTicket from './Form'
import History from './History'
import ModalNewState from './ModalNewState'
import ModalChangeState from './ModalChangeState'
import ModalStockists from './ModalStockists'
import ModalChangeCustomer from './ModalChangeCustomer'
import {getUser} from '../../../reducers'
import PrintTicket from './PrintTicket'
import {setTitle} from '../../../actions'


const Ticket = () => {

  const componentRef = useRef()
  const user = useSelector(getUser)
  const dispatch = useDispatch()
  const match = useRouteMatch()
  const history = useHistory()

  const [note, setNote] = useState('')
  const [historyId, setHistoryId] = useState(null)
  const [openModalNewState, setOpenModalNewState] = useState(false)
  const [openModalStockists, setOpenModalStockists] = useState(false)
  const [openModalChangeCustomer, setOpenModalChangeCustomer] = useState(false)
  const [openModalChangeState, setOpenModalChangeState] = useState(false)

  const id = match.params.ID === 'new' ? null : match.params.ID

  useTitle((title) => dispatch(setTitle(title)), id ? 'Dettaglio ticket' : 'Nuovo ticket')

  const customerID = match.params.customerID || null

  const options = {
    id,
    redirect: history.push,
    withoutEffect: match.params.ID === 'new'
  }
  const {
    entities: ticket,
    setEntities: setTicket,
    getOne,
    remove,
    asyncUpdate: changeCustomer
  } = useEntities('tickets', options)

  const entityOption = {
    isNotPaginated: true,
    withoutLoading: !!id
  }
  const enabledOption = {
    isNotPaginated: true,
    initialFilters: {enabled: true},
    withoutLoading: !!id
  }

  const {entities: templates} = useEntities('ticket-templates', entityOption)
  const {entities: categories} = useEntities('ticket-categories', {
    isNotPaginated: true,
    initialFilters: {
      enabled: true,
      withProblemTypes: true
    },
    withoutLoading: !!id
  })
  const {entities: subcategories} = useEntities('ticket-subcategories', enabledOption)
  const {entities: problemTypes} = useEntities('ticket-problemtypes', enabledOption)
  const {entities: priorities} = useEntities('ticket-priorities', entityOption)
  const {entities: sources} = useEntities('ticket-sources', enabledOption)
  const {entities: states} = useEntities('ticket-states', entityOption)
  const {entities: types} = useEntities('ticket-types', entityOption)
  const {entities: users} = useEntities('users', enabledOption)
  const {entities: customer} = useEntities('customers', {id: match.params.customerID, withoutLoading: true})

  const changeTicketCustomer = customerSelected => {
    changeCustomer({}, id, {action: `customers/${customerSelected.id}`, throwError: true})
      .then(() => {
        setOpenModalChangeCustomer(false)
        history.push(`/`)
        history.push(`/customers/${customerSelected.id}/tickets/${ticket.id}`)
      })
  }

  useEffect(() => {

    if (!customer || !ticket || !ticket.customer ) return
    if(Array.isArray(customer)) return
    if (ticket.customer.id !== customer.id)
      history.push('/notFound')
  }, [customer, ticket])

  return (
    <PageContainer>
      <FormTicket
        history={history}
        entity={{...ticket, customerCode: customer ? customer.code : ''}}
        remove={remove}
        categories={categories}
        customers={[customer]}
        priorities={priorities}
        problemTypes={problemTypes}
        subcategories={subcategories}
        sources={sources}
        userIsAdmin={user.role.isAdmin}
        states={states}
        types={types}
        templates={templates}
        users={users}
        setTicket={setTicket}
        customerID={customerID}
        setOpenModal={setOpenModalStockists}
        setOpenModalCustomer={setOpenModalChangeCustomer}
      />
      {ticket.id && <History
        user={user}
        history={ticket ? ticket.history : []}
        setOpenModal={setOpenModalNewState}
        setOpenModalChangeState={setOpenModalChangeState}
        isClosed={ticket.state.id === 2}
        customer={customer}
        ticket={ticket}
        componentRef={componentRef}
        setHistoryId={setHistoryId}
        refreshData={getOne}
      />}
      <ModalNewState
        note={note}
        setNote={setNote}
        isOpened={openModalNewState}
        entity={ticket}
        closeModal={() => setOpenModalNewState(false)}
        states={states}
        users={users}
        refreshData={getOne}
      />
      <ModalChangeState
        isOpened={openModalChangeState}
        entity={(ticket && historyId && ticket.history) ? ticket.history.find(h => h.id === historyId) : {}}
        closeModal={() => setOpenModalChangeState(false)}
        states={states}
        users={users}
        refreshData={getOne}
        ticketId={ticket.id}
      />
      <ModalStockists
        isOpened={openModalStockists}
        ticket={ticket}
        closeModal={() => setOpenModalStockists(false)}
        stockists={ticket.stockists}
        refreshData={getOne}
        setTicket={setTicket}
      />
      <ModalChangeCustomer
        isOpened={openModalChangeCustomer}
        ticket={ticket}
        closeModal={() => setOpenModalChangeCustomer(false)}
        customers={customer ? [customer] : []}
        stockists={ticket.stockists}
        refreshData={getOne}
        changeTicketCustomer={changeTicketCustomer}
        setTicket={setTicket}
      />
      {ticket && customer &&
      <PrintTicket
        ref={componentRef}
        ticket={ticket}
        customer={customer}
      />
      }
    </PageContainer>
  )
}

/* region STYLE */

const PageContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  
  @media (max-width: 1650px) {
    flex-wrap: wrap;
  }
`
/* endregion */

export default Ticket
