import React from 'react'
import styled from 'styled-components'
import {Field, Form} from 'react-final-form'
import useEntities from '../../../hooks/useEntities'
import {ButtonGreen, Modal, Select, ErrorMessage} from '../../common'
import imgAdd from '../../../images/ico-add.svg'
import {required} from '../../../helpers/formErrors'
import RichTextEditor from './RichTextEditor'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const ModalChangeState = ({isOpened, closeModal, entity, ticketId, states, refreshData}) => {

  const {call: modify} = useEntities('', {withoutEffect: true})

  //region Form
  const onSubmit = async (values) => {
    const toSubmit = {}
    toSubmit.id = entity.id
    toSubmit.state = {id: values.stateId}
    toSubmit.note = values.note
    try {
      await modify('put', `/tickets/${ticketId}/history/${values.id}`, toSubmit)
      refreshData()
      closeModal()
    } catch (e) {
      console.log(e)
    }
  }

  const getInitialValues = (initialValues) => {
    const values = initialValues || {}
    values.stateId = (values && values.state) && values.state.id
    return values
  }
  //endregion

  return (
    <Modal
      isOpened={isOpened}
      closeModal={closeModal}
      initWidth={700}
      initHeight={500}
    >
      <ModalContainer data-cy={'modal-history'}>
        <ModalTitle>Modifica Stato Ticket</ModalTitle>
        <ModalFormContainer>
          <Form
            onSubmit={onSubmit}
            initialValues={getInitialValues(entity)}
            render={({handleSubmit, values, form}) => (
              <ModalFormContainer>
                <InputRow>
                  <InputBox data-cy={'entity-state'}>
                    <Field
                      name="stateId"
                      validate={required}
                    >
                      {({input, meta}) => (
                        <>
                          <Select
                            disabled={!entity.id}
                            type="text"
                            label="Stato ticket"
                            {...input}
                          >
                            <option>{null}</option>
                            {states && states.map(entity =>
                              <option key={entity.id} value={entity.id}>{entity.name}</option>
                            )}
                          </Select>
                          {meta.error && meta.touched && <ErrorMessage message={meta.error}/>}
                        </>
                      )}
                    </Field>
                  </InputBox>
                </InputRow>
                <InputBox width="650px" data-cy={'entity-note'}>
                  <Field
                    name="note"
                    component={RichTextEditor}
                    validate={required}
                    note={values.note}
                  />
                </InputBox>
                <ButtonGreen
                  dataCy={'save-history'}
                  text='Salva'
                  image={imgAdd}
                  marginText
                  minWidth={'242px!important'}
                  type='submit'
                  onClick={handleSubmit}
                />
              </ModalFormContainer>
            )}
          />
        </ModalFormContainer>
      </ModalContainer>
    </Modal>
  )
}

/* region STYLE */
const ModalContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: 100%;
`

const ModalTitle = styled.div`
  font-size: 1.2rem;
  opacity: 0.7;
  font-weight: bold;
`

const ModalFormContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
  width: 100%;
`

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  color: #000000 !important;
`

const InputBox = styled.div`
  margin: 20px;
  opacity: 0.9;
  ${({width}) => `width: ${width}`};
`
/* endregion */

export default ModalChangeState