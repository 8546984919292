import React from 'react'
import Mod from 'react-modal-resizable-draggable'
import styled from 'styled-components'
import imgClose from '../../images/close-grey.svg'
import '../../styles/modal.css'


const Modal = ({ isOpened, closeModal, initWidth, initHeight, children }) => {
  return(
    <Mod
      isOpen={isOpened}
      onRequestClose={closeModal}
      initWidth={initWidth || 800}
      initHeight={initHeight || 700}
      disableResize
    >
      <CloseButton onClick={closeModal}><img src={imgClose} alt="close" /></CloseButton>
      {children}
    </Mod>
  )
}

export { Modal }

//region Style

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 2;
  background: #F4F4F4;
  border-top-right-radius: 15px;
  & img {
    width: 20px;
    height: 20px;
  }
`

//endregion
