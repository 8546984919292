import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import useTitle from '../../../hooks/useTitle'
import {setTitle} from '../../../actions'
import {useRouteMatch} from 'react-router'
import withPagination from '../../withPagination'
import Filters from './Filters'
import Table from './Table'
import useEntities from '../../../hooks/useEntities'
import {getPageFilters} from '../../../reducers'


const TablePaginated = withPagination(Filters, Table)


const PageCustomerHistory = () => {

    const match = useRouteMatch()
    const filters = useSelector((state) => getPageFilters(state, match.url))
    const dispatch = useDispatch()

    const idCustomer = match.params.customerID
    const options = {
        isNotPaginated: false,
        initialFilters: {
            ...filters,
            withFirstNote: true,
            customerId: parseInt(idCustomer),
            orderBy: "created",
            orderType: "desc",
            start: 0,
            limit: 25
        }
    }
    const openedOptions = {
        isNotPaginated: true,
        initialFilters: {
            ...filters,
            opened: true,
            withFirstNote: true,
            customerId: parseInt(idCustomer),
            orderBy: "created",
            orderType: "desc",
        }
    }
    const closedOptions = {
        initialLimit: 25,
        initialFilters: {
            ...filters,
            states: [2],
            withFirstNote: true,
            customerId: parseInt(idCustomer),
            orderBy: "created",
            orderType: "desc",
        }
    }

    const {entities: openedTicket, get: getOpened} = useEntities('tickets', openedOptions)
    const {entities: closedTicket, total: closedTotal, get: getClosed} = useEntities('tickets', closedOptions)
    const {entities: states} = useEntities('ticket-states', {isNotPaginated: true})
    const {entities: ticketType} = useEntities('ticket-types', {isNotPaginated: true})
    const {entities: priorities} = useEntities('ticket-priorities', {isNotPaginated: true})
    const {entities: categories} = useEntities('ticket-categories', {isNotPaginated: true})
    const {entities: subcategories} = useEntities('ticket-subcategories', {isNotPaginated: true})
    const {entities: problemTypes} = useEntities('ticket-problemtypes', {isNotPaginated: true})
    const {entities: customer} = useEntities('customers', {id: idCustomer})


    const [open, setOpen] = useState(false)

    useTitle((title) => dispatch(setTitle(title)), `Cronologia ticket del cliente ${customer.name || customer.businessName} ${customer.surname || customer.structureName}`)

    return (
        <TablePaginated
            opened={openedTicket}
            closed={closedTicket}
            total={closedTotal}
            get={(filters, page, itemsPerPage) => {
                getOpened({...openedOptions.initialFilters, ...filters, opened: true})
                getClosed({...closedOptions.initialFilters, ...filters, states: [2]}, page, itemsPerPage)
            }}
            idCustomer={idCustomer}
            states={states}
            ticketType={ticketType}
            priorities={priorities}
            categories={categories}
            subcategories={subcategories}
            problemTypes={problemTypes}
            initialFilters={options.initialFilters || null}
            open={open}
            setOpen={setOpen}
            initialLimit={25}
        />
    )
}

export default PageCustomerHistory
